import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function AboutTek({data}) {
    const [activeTab, setActiveTab] = useState(0);

    function displayTab(tabNumber) {
        setActiveTab(tabNumber);
    }

    return (
        <div className="p-100 pt-0">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <ul className="custom_tab">
                            <li onClick={() => {
                                displayTab(0)
                            }} className={`tabs ${activeTab === 0 ? 'active' : ''}`}>
                                <div className="example" data-text={data?.mission_title}>
                                    <h2>{data?.mission_title}</h2>
                                </div>
                            </li>

                            <li onClick={() => {
                                displayTab(1)
                            }} className={`tabs ${activeTab === 1 ? 'active' : ''}`}>
                                <div className="example" data-text={data?.vission_title}>
                                    <h2>{data?.vission_title}</h2>
                                </div>
                            </li>
                            <li onClick={() => {
                                displayTab(2)
                            }} className={`tabs ${activeTab === 2 ? 'active' : ''}`}>
                                <div className="example" data-text={data?.value_title}>
                                    <h2>{data?.value_title}</h2>
                                </div>
                            </li>
                        </ul>
                        <Row className={activeTab === 0 ? 'd-flex h-100' : 'd-none h-100'}>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <div>{HtmlParser(checkData(data, 'mission_description'))}</div>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <img
                                    src={`${mediaBaseURL}${checkData(data, 'mission_image')}`}
                                    className="img-fluid"
                                    alt={checkData(data, 'mission_title')}/>
                            </Col>
                        </Row>
                        <Row className={activeTab === 1 ? 'd-flex h-100' : 'd-none h-100'}>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <div>{HtmlParser(checkData(data, 'vission_description'))}</div>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <img
                                    src={`${mediaBaseURL}${checkData(data, 'vission_image')}`}
                                    className="img-fluid"
                                    alt={checkData(data, 'vission_title')}/>
                            </Col>
                        </Row>
                        <Row className={activeTab === 2 ? 'd-flex h-100' : 'd-none h-100'}>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <div>{HtmlParser(checkData(data, 'value_description'))}</div>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <img
                                    src={`${mediaBaseURL}${checkData(data, 'value_image')}`}
                                    className="img-fluid"
                                    alt={checkData(data, 'value_title')}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
        ;
}

export default AboutTek;
